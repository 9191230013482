/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const BIG_PLACEHOLDER_CONFIG = [6, 10, 7];
export const SMALL_PLACEHOLDER_CONFIG = [5];

export const COLOR_ATTRIBUTE = 'farve_gruppe_1';
export const STONE_ATTRIBUTE = 'farve_paa_sten';
export const MATERIAL_ATTRIBUTE = 'piercing_materiale';
export const STOERRELESE = 'stoerrelse';
export const GEMSTONE_TYPE = 'gemstone_type';
export const MOTIV = 'motiv';
export const INDHOLD = 'indhold';

export const SELECTEBALE_ATTRIBUTES_ARRAY = [
    COLOR_ATTRIBUTE, STONE_ATTRIBUTE, MATERIAL_ATTRIBUTE, STOERRELESE, GEMSTONE_TYPE, MOTIV, INDHOLD
];
