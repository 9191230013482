/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */

import {
    createRef,
    Suspense
} from 'react';

import Button from 'Component/Button';
import { SIZE_NONE, TYPE_TEXT, WIDTH_HUG } from 'Component/Button/Button.config';
import CartIcon from 'Component/CartIcon';
import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import CmsBlock from 'Component/CmsBlock';
// import FIELD_TYPE from 'Component/Field/Field.config';
import HeartIcon from 'Component/HeartIcon';
import { BLACK_KEY, M_KEY } from 'Component/Icon/Icon.config';
import Link from 'Component/Link';
import SearchField from 'Component/SearchField';
import { HEADER_STORE_SELECTOR } from 'Component/StoreSelector/StoreSelector.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import UserIcon from 'Component/UserIcon';
import { BILLING_STEP, SUCCESS_URL } from 'Route/Checkout/Checkout.config';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';

import { TYPE_LINK } from '../Button/Button.config';
import { SEARCH } from './Header.config';

import './Header.style';

export const Menu = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "menu" */
        'Component/Menu'
    ), 2
);

export const MyAccountOverlay = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "header-account" */
        'Component/MyAccountOverlay'
    ), 2
);

export const StoreSelector = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "header-additional" */
        'Component/StoreSelector'
    ), 2
);

export const CartOverlay = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "header-additional" */
        'Component/CartOverlay'
    ), 2
);

/** @namespace Bodypwa/Component/Header/Component */
export class HeaderComponent extends SourceHeader {
    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        logo: this.renderLogo.bind(this),
        search: this.renderSearchField.bind(this),
        headerlinks: this.renderHeaderLinks.bind(this),
        ok: this.renderOkButton.bind(this)
    };

    headerRef = createRef();

    navigationRef = createRef();

    headerTopRef = createRef();

    renderMapDesktop = {
        account: this.renderAccount.bind(this),
        wishlist: this.renderWishList.bind(this),
        minicart: this.renderMinicart.bind(this)
    };

    componentDidUpdate() {
        const {
            setHeaderHeight, setHeaderPlaceholderHeight, setHeaderElement, setHeaderTopRef
        } = this.props;
        const height = this.logoRef?.current?.offsetHeight;

        setHeaderHeight(height || 0);
        setHeaderPlaceholderHeight(height + 32);

        if (this.logoRef?.current) {
            setHeaderElement(this.logoRef.current);
        }

        if (this.headerTopRef?.current) {
            setHeaderTopRef(this.headerTopRef.current);
        }
    }

    renderTopMenu() {
        return (
            <>
                <div
                  block="Topbanner"
                  elem="Wrapper"
                >
                    <div block="Topbanner" elem="Columns">
                        { this.renderContent('social_reviews_top') }
                    </div>
                </div>
                <div
                  block="Topbanner"
                  elem="Wrapper"
                  mods={ { labels: true } }
                >
                    <div block="Topbanner" elem="Columns">
                        { this.renderContent('pwa-header-labels-desktop', 'pwa-header-labels-mobile') }
                    </div>
                </div>
            </>
        );
    }

    renderWishList() {
        const { onWishlistClick, hideWishlistButton } = this.props;
        const link = isSignedIn() ? '/my-account/my-wishlist' : '/account/login';

        if (hideWishlistButton) {
            return null;
        }

        return (
            <Link
              tabIndex="0"
              to={ link }
              aria-label={ __('Go to wishlist') }
              block="Header"
              onClick={ onWishlistClick }
              elem="WishlistLinkWrapper"
              key="linkWishlist"
            >
                <HeartIcon />
            </Link>
        );
    }

    renderMinicartOverlay() {
        const { shouldRenderCartOverlay } = this.props;

        if (!shouldRenderCartOverlay) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <CartOverlay />
            </Suspense>
        );
    }

    renderMinicart(isVisible = false) {
        const {
            onMinicartOutsideClick,
            isCheckout,
            device: { isMobile }
        } = this.props;

        if (isMobile || (isCheckout && this.isSuccsessPage())) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMinicartOutsideClick }
              key="minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'minicart' } }
                >
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay
        } = this.props;

        if (isCheckout && this.isSuccsessPage()) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    renderDesktopActions() {
        const { device: { isMobile } } = this.props;
        if (isMobile) {
            return null;
        }

        return Object.entries(this.renderMapDesktop).map(
            // eslint-disable-next-line no-unused-vars
            ([key, renderFunction]) => renderFunction()
        );
    }

    renderHeaderLinks() {
        const { device: { isMobile }, topPageLinks } = this.props;

        if (isMobile || !topPageLinks) {
            return null;
        }

        return (
            <div
              block="Header"
              elem="Links"
            >
                { this.renderColumns(topPageLinks) }
            </div>
        );
    }

    renderColumns = (columns) => {
        const { items } = columns;
        const { device } = this.props;
        if (device.isMobile) {
            return (
                <div
                  mix={ { block: 'Header', elem: 'Column' } }
                  className="Header-ColumnContent"
                  // eslint-disable-next-line react/jsx-boolean-value
                  isArrow={ true }
                  isContentExpanded={ false }
                >
                    <div
                      block="Header"
                      elem="ColumnContent"

                    >
                        { items ? items.map(this.renderColumnItem) : columns.map(this.renderColumnItem) }
                    </div>

                </div>
            );
        }

        return (
            <div block="Header" elem="Column">
                <div
                  block="Header"
                  elem="ColumnContent"
                >
                    { items ? items.map(this.renderColumnItem) : columns.map(this.renderColumnItem) }
                    <div block="Header" elem="Switcher">
                        <StoreSelector isSimple storeSelectorId={ HEADER_STORE_SELECTOR } isRedirectPopUp />
                    </div>
                </div>
            </div>
        );
    };

    renderColumnItem = (item, i) => {
        const { url, label } = item;
        return (
            <Button
              goTo={ url }
              key={ i }
              isLink
              size={ SIZE_NONE }
              width={ WIDTH_HUG }
              type={ TYPE_LINK }
            >
                { label }
            </Button>
        );
    };

    renderTitle() {
        return null;
    }

    renderContent(identifierDesktop, identifierMobile) {
        const isMobile = window.matchMedia('(max-width: 1023px)').matches;
        if (!identifierDesktop) {
            return null;
        }

        if (isMobile) {
            return (
            <CmsBlock identifier={ identifierMobile || identifierDesktop } />
            );
        }

        return (
            <CmsBlock identifier={ identifierDesktop } />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    isSuccsessPage() {
        const {
            location: {
                pathname
            }
        } = history;

        return !pathname.includes(SUCCESS_URL);
    }

    renderMenu() {
        const {
            isCheckout, device: { isMobile }, headerHeight, headerMenu
        } = this.props;

        if (isMobile || (isCheckout && this.isSuccsessPage()) || !headerMenu) {
            return null;
        }

        return <Menu headerHeight={ headerHeight } />;
    }

    renderAccount(isVisible = false) {
        const {
            isCheckout,
            device: { isMobile }
        } = this.props;

        // on mobile hide button if not in checkout
        if (isMobile && !isCheckout) {
            return null;
        }

        if (isCheckout && isSignedIn() && this.isSuccsessPage()) {
            return null;
        }

        return (
            <>
                { this.renderAccountButton(isVisible) }
                { this.renderAccountOverlay() }
            </>
        );
    }

    renderAccountButton() {
        const {
            onMyAccountButtonClick,
            device
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <Button
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              type={ TYPE_TEXT }
              width={ WIDTH_HUG }
              size={ SIZE_NONE }
            >
                <UserIcon size={ M_KEY } color={ BLACK_KEY } />
            </Button>
        );
    }

    renderPaymentBackButton() {
        const {
            activeStep
        } = this.props;

        if (activeStep === BILLING_STEP) {
            return (
                <Button
                  gTto="/checkout/shipping"
                  type={ TYPE_TEXT }
                  width={ WIDTH_HUG }
                  isLink
                  size={ SIZE_NONE }

                >
                   <ChevronIcon direction={ LEFT } size={ M_KEY } color={ BLACK_KEY } />
                </Button>
            );
        }

        return null;
    }

    renderHeader() {
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            setIsCheckout,
            activeOverlay,
            headerPlaceholderHeight,
            headerHeight
        } = this.props;

        document.documentElement.style.setProperty('--header-height', `${headerPlaceholderHeight}px`);
        setIsCheckout();
        if (isCheckout && this.isSuccsessPage()) {
            return (
                <section
                  block="Header"
                  className={ isCheckout ? 'Header-Checkout' : '' }
                  elem="Wrapper"
                  ref={ this.headerRef }
                >
                    <header
                      block="CheckoutHeader"
                      mods={ { name, isHiddenOnMobile, isCheckout } }
                      mix={ { block: 'FixedElement', elem: 'Top' } }
                      ref={ this.logoRef }
                    >
                        <nav block="Header" elem="Nav">
                            { this.renderPaymentBackButton() }
                            { this.renderLogo(true) }
                        </nav>
                    </header>
                </section>
            );
        }

        return (
            <section
              block="Header"
              elem="Wrapper"
              style={ {
                  '--header-dinamic-height': `${headerPlaceholderHeight}px`,
                  '--header-height': `${headerHeight}px`,
                  // eslint-disable-next-line no-magic-numbers
                  '--offset-left': `${ (this.navigationRef?.current?.getBoundingClientRect().left + 32) || 0}px`,
                  minHeight: headerPlaceholderHeight
              } }
              ref={ this.headerRef }
            >
                { this.renderTopMenu() }
                <div ref={ this.headerTopRef } />
                <header
                  block="Header"
                  mods={ {
                      name,
                      isHiddenOnMobile,
                      isCheckout,
                      activeCatOverlay: activeOverlay === CATEGORY_FILTER_OVERLAY_ID,
                      scrollTop: !+document.documentElement.dataset.scrollPosition
                  } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    <nav block="Header" elem="Nav" ref={ this.navigationRef }>
                        { this.renderNavigationState() }
                    </nav>
                    <div block="Header" elem="Bottom">
                        <div block="Header" elem="Buttons">
                            { this.renderDesktopActions() }
                        </div>
                        { this.renderMenu() }
                    </div>
                </header>
            </section>
        );
    }

    renderAccountOverlay() {
        const {
            isCheckout,
            showMyAccountLogin,
            onSignIn
        } = this.props;

        // This is here to prevent the popup-suspense from rendering
        if (!showMyAccountLogin) {
            return null;
        }

        return (
                <MyAccountOverlay
                  onSignIn={ onSignIn }
                  isCheckout={ isCheckout }
                />
        );
    }

    renderLogo(isVisible = false) {
        const { isLoading } = this.props;
        const content = isLoading ? <TextPlaceholder height="logo" length="logo" />
            : this.renderLogoImage();

        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on ScandiPWA logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { content }
            </Link>
        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <Button
              tabIndex="0"
              onClick={ onMinicartButtonClick }
              aria-label={ __('Cart') }
              type={ TYPE_TEXT }
              width={ WIDTH_HUG }
              size={ SIZE_NONE }
            >

                <CartIcon size={ M_KEY } color={ BLACK_KEY } isTransparent={ false } isChecked={ false } />
                { this.renderMinicartItemsQty() }
            </Button>
        );
    }

    render() {
        return this.renderHeader();
    }
}

export * from 'SourceComponent/Header/Header.component';
export default HeaderComponent;
