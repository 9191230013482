import { Suspense } from 'react';

import { lazyComponentLoader } from 'Util/lazyComponentLoader';

import { ADYEN_CC } from '../component/AdyenCC/AdyenCC.config';
import { ADYEN_HPP } from '../component/AdyenHPP/AdyenHPP.config';

export const AdyenCC = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-adyen" */ '../component/AdyenCC'
), 2);
export const AdyenHPP = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-adyen" */ '../component/AdyenHPP'
), 2);

export const CheckoutPayment = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Component/CheckoutPayment'
), 2);
const renderPayment = (args, callback, instance) => {
    const [method] = args;
    const {
        selectPaymentMethod,
        selectedPaymentCode,
        selectedMethodType,
        currencyCode,
        paymentMethods
    } = instance.props;

    const { code, type = '' } = method;
    const isSelected = type ? type === selectedMethodType : code === selectedPaymentCode;

    const isPaymentMethod = paymentMethods.find((singleMethod) => {
        if (singleMethod.code === selectedPaymentCode) {
            return singleMethod;
        }
    });

    return (
        <CheckoutPayment
          key={ `${code}_${type}` }
          isSelected={ isSelected }
          method={ method }
          renderPayment={ instance.paymentRenderMap }
          onClick={ selectPaymentMethod }
          currencyCode={ currencyCode }
        />
    );
};

const renderAdyen = () => (
        <Suspense fallback={ <div>Loading...</div> }>
             <AdyenCC />
        </Suspense>
);

export function renderAdyenHPP() {
    const { paymentMethods, selectedMethodType, adyenComponent } = this.props;
    const method = paymentMethods.find((singleMethod) => {
        if (singleMethod.type === selectedMethodType) {
            return singleMethod;
        }
    });

    if (!method) {
        return null;
    }

    return (
        <Suspense fallback={ <div>Loading...</div> }>
            <AdyenHPP
              adyenComponent={ adyenComponent }
              method={ method }
            />
        </Suspense>
    );
}

export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [ADYEN_CC]: renderAdyen.bind(instance),
    [ADYEN_HPP]: renderAdyenHPP.bind(instance)
});

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderPayment,
            paymentRenderMap
        }
    }
};
