import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

/** @namespace Bodypwa/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreConfigFields() {
        return [
            'code',
            'is_active',
            'cms_home_page',
            'cms_no_route',
            'copyright',
            'timezone',
            'header_logo_src',
            'timezone',
            'title_prefix',
            'title_suffix',
            'default_display_currency_code',
            'grid_per_page_values',
            'grid_per_page',
            'pagination_enabled',
            'default_keywords',
            'default_title',
            'default_description',
            'default_country',
            'downloadable_links_target_new_window',
            'secure_base_media_url',
            'free_shipping_threshold',
            'logo_alt',
            'logo_height',
            'logo_width',
            'cookie_text',
            'cookie_link',
            'terms_are_enabled',
            'address_lines_quantity',
            'base_url',
            'pagination_frame',
            'pagination_frame_skip',
            'anchor_text_for_previous',
            'anchor_text_for_next',
            'reviews_are_enabled',
            'reviews_allow_guest',
            'wishlist_general_active',
            'demo_notice',
            'guest_checkout',
            'is_email_confirmation_required',
            'display_product_stock_status',
            'base_link_url',
            'show_vat_number_on_storefront',
            'show_tax_vat_number',
            'product_use_categories',
            'category_url_suffix',
            'cookie_lifetime',
            'plp_list_mode',
            'layered_navigation_product_count_enabled',
            'region_display_all',
            'redirect_dashboard',
            'product_alert_allow_price',
            'product_alert_allow_stock',
            'newsletter_general_active',
            'newsletter_subscription_allow_guest_subscribe',
            'newsletter_subscription_confirm',
            'delivery_instore_active',
            'access_token_lifetime',
            'bodymod_app_version',
            'header_menu',
            'country_popup_image_desktop',
            'country_popup_image_mobile',
            'welcome_page_active',
            'welcome_background_image_mobile',
            'welcome_background_image_desktop',
            'welcome_type',
            'welcome_page_title',
            'welcome_page_description',
            'country_popup_active',
            'country_popup_description',
            'countriesRequireState',
            'currency_configurations',
            this._getGoogleRecaptchaFields(),
            this._getCookieScriptFields(),
            this._getTopHeaderFields(),
            this.getPriceDisplayTypeField(),
            this._getFooterSectionsFields(),
            this._getFooterSectionsPaymentFields(),
            this._getFooterSectionsDeliveryFields(),
            this._getPachetaApiDetailsFields(),
            this._getAmastyCartSellingOptionFields()
        ];
    }

    _getAmastyCartSellingOptionFields() {
        return new Field('amasty_cart_selling_option')
            .addFieldList([
                'block_subtitle',
                'block_title',
                'block_type',
                'cms_block',
                'products_qty_limit'
            ]);
    }

    _getGoogleRecaptchaFields() {
        return new Field('google_recaptcha')
            .addFieldList([
                'site_key',
                'script_url'
            ]);
    }

    _getCookieScriptFields() {
        return new Field('cookie_script')
            .addFieldList([
                'enabled',
                'url_key'
            ]);
    }

    _getFooterSectionsPaymentFields() {
        return new Field('footer_section_payment_icons')
            .addFieldList([
                'isItemsHorizontal',
                this._getFooterSectionsIconFields()
            ]);
    }

    _getFooterSectionsDeliveryFields() {
        return new Field('footer_section_carrier_icons')
            .addFieldList([
                'isItemsHorizontal',
                this._getFooterSectionsIconFields()
            ]);
    }

    _getFooterSectionsFields() {
        return new Field('footer_section_links')
            .addFieldList([
                'title',
                'expandable',
                this._getFooterSectionsItemFields()
            ]);
    }

    _getFooterSectionsItemFields() {
        return new Field('items')
            .addFieldList([
                'href',
                'title'
            ]);
    }

    _getFooterSectionsIconFields() {
        return new Field('items')
            .addFieldList([
                'src',
                'title'
            ]);
    }

    _getStoreListFields() {
        return [
            'name',
            'is_active',
            'base_url',
            'base_link_url',
            'code',
            'store_group_name',
            'locale'
        ];
    }

    _getTopHeaderFields() {
        return new Field('bms_header_links')
            .addFieldList([
                'url',
                'label'
            ]);
    }

    _getPachetaApiDetailsFields() {
        return new Field('pachetaApiDetails')
            .addFieldList([
                'endpointUrl',
                'apiKey',
                'language'
            ]);
    }
}

export default new ConfigQuery();
