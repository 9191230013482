import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';
/** @namespace Bodypwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    getReactivateCartMutation(cartId) {
        return new Field('reactivateCart')
            .addArgument('cart_id', 'String!', cartId);
    }

    _getEstimatedShippingFields() {
        return [
            'amount',
            'available',
            'base_amount',
            'method_code',
            'carrier_code',
            'method_title',
            'carrier_title',
            'error_message',
            'price_excl_tax',
            'price_incl_tax',
            'description',
            'shipping_method_type_detail'
        ];
    }

    _getPaymentMethodFields() {
        return ['code', 'title', 'fee'];
    }
}

export default new CheckoutQuery();
