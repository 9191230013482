import { CHECKOUT_URL, SUCCESS_URL } from 'Route/Checkout/Checkout.config';
import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS,
    updateEmail,
    updateEmailAvailable,
    updateShippingFields
} from 'SourceStore/Checkout/Checkout.action';
import history from 'Util/History';

export {
    UPDATE_SHIPPING_FIELDS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    updateShippingFields,
    updateEmail,
    updateEmailAvailable
};

export const GET_IS_CHECKOUT = 'GET_IS_CHECKOUT';
export const UPDATE_FORM = 'UPDATE_FORM';
export const ADYEN_CC_IS_LOADING = 'ADYEN_CC_IS_LOADING';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const IS_CHECKOUT_LOADING = 'IS_CHECKOUT_LOADING';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const RESET_SHIPPING_FIELDS = 'RESET_SHIPPING_FIELDS';
export const SET_CUSTOMER_ADDRESS_ID = 'SET_CUSTOMER_ADDRESS_ID';

/** @namespace Bodypwa/Store/Checkout/Action/updateForm */
export const updateForm = (form) => ({
    type: UPDATE_FORM,
    form
});

/** @namespace Bodypwa/Store/Checkout/Action/setAdyenCCisLoading */
export const setAdyenCCisLoading = (isAdyenCCLoading) => ({
    type: ADYEN_CC_IS_LOADING,
    isAdyenCCLoading
});

/** @namespace Bodypwa/Store/Checkout/Action/setIsCheckout */
export const setIsCheckout = () => {
    const {
        location: {
            pathname
        }
    } = history;
    const isCheckout = pathname.includes(CHECKOUT_URL) && !pathname.includes(SUCCESS_URL);
    return {
        type: GET_IS_CHECKOUT,
        isCheckout
    };
};

/** @namespace Bodypwa/Store/Checkout/Action/setActiveStep */
export const setActiveStep = (step) => ({
    type: SET_ACTIVE_STEP,
    step
});

/** @namespace Bodypwa/Store/Checkout/Action/setIsCheckoutLoading */
export const setIsCheckoutLoading = (isLoading) => ({
    type: IS_CHECKOUT_LOADING,
    isLoading
});

/** @namespace Bodypwa/Store/Checkout/Action/resetCheckout */
export const resetCheckout = () => ({
    type: RESET_CHECKOUT
});

/** @namespace Bodypwa/Store/Checkout/Action/resetShippingFields */
export const resetShippingFields = () => ({
    type: RESET_SHIPPING_FIELDS
});

/** @namespace Bodypwa/Store/Checkout/Action/setCustomerAdressId */
export const setCustomerAdressId = (addressId) => ({
    type: SET_CUSTOMER_ADDRESS_ID,
    addressId
});
