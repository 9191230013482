import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

/** @namespace Bodypwa/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Bodypwa/Component/Popup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Bodypwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    setIsClosing = (isClosing) => {
        this.setState({ isClosing });
    };

    state = {
        isClosing: false
    };

    containerProps() {
        const {
            clickOutside,
            checkmark = false,
            isNoTransition = false,
            popUpHeight,
            isHideHeader = false,
            headerRef,
            overlayHtml = '',
            isGoBack = true,
            onCustomClose
        } = this.props;

        const { isClosing } = this.state;

        return {
            ...super.containerProps(),
            clickOutside,
            checkmark,
            isClosing,
            isNoTransition,
            popUpHeight,
            isHideHeader,
            headerRef,
            setIsClosing: this.setIsClosing,
            overlayHtml,
            onCustomClose,
            isGoBack
        };
    }

    _getPopupTitle() {
        const { payload, activeOverlay, title: ogTitle } = this.props;
        const { title } = payload[activeOverlay] || {};

        return ogTitle || title;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
