import { CUSTOMER_BILLING_ADDRESS, CUSTOMER_SHIPPING_ADDRESS } from
'Component/CheckoutAddressForm/CheckoutAddressForm.config';
import {
    CheckoutReducer as sourceCheckoutReducer,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Checkout/Checkout.reducer';

import {
    ADYEN_CC_IS_LOADING,
    GET_IS_CHECKOUT,
    IS_CHECKOUT_LOADING,
    RESET_CHECKOUT,
    RESET_SHIPPING_FIELDS,
    SET_ACTIVE_STEP,
    SET_CUSTOMER_ADDRESS_ID,
    UPDATE_FORM,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';

/** @namespace Bodypwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isCheckout: false,
    isAdyenCCLoading: false,
    firstname: '',
    lastname: '',
    isCheckoutLoading: false,
    paymentMethods: [],
    [CUSTOMER_BILLING_ADDRESS]: {},
    [CUSTOMER_SHIPPING_ADDRESS]: {},
    customerAddressId: 0
});

/** @namespace Bodypwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case GET_IS_CHECKOUT:
        const { isCheckout } = action;
        return {
            ...state,
            isCheckout
        };
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;
        return {
            ...state,
            shippingFields
        };
    case RESET_SHIPPING_FIELDS:
        return {
            ...state,
            shippingFields: {}
        };
    case UPDATE_FORM:
        const { form } = action;
        return {
            ...state,
            ...form
        };
    case ADYEN_CC_IS_LOADING:
        const { isAdyenCCLoading } = action;
        return {
            ...state,
            isAdyenCCLoading
        };
    case SET_ACTIVE_STEP:
        const { step } = action;
        return {
            ...state,
            activeStep: step
        };
    case IS_CHECKOUT_LOADING:
        const { isLoading } = action;
        return {
            ...state,
            isCheckoutLoading: isLoading
        };
    case RESET_CHECKOUT:
        return {
            ...getInitialState()
        };
    case SET_CUSTOMER_ADDRESS_ID:
        const { addressId } = action;
        return {
            ...state,
            customerAddressId: addressId
        };
    default:
        return sourceCheckoutReducer(state, action);
    }
};

export default CheckoutReducer;
