import getStore from 'Util/Store';

import currencyMap, {
    AFTER,
    AFTER_WITH_SPACE,
    BEFORE, BEFORE_VALUE,
    BEFORE_WITH_SPACE
} from './Price.config';
/** @namespace Bodypwa/Util/Price/formatCurrency */
export const formatCurrency = (currency = 'USD') => currencyMap[currency];
/** Format price based on PRICE_CONFIGS * @namespace Bodypwa/Util/Price/applyCustomFormatting */
export const applyCustomFormatting = (price, currency, config) => {
    const {
        symbol = currencyMap[currency],
        show_symbol = AFTER,
        group_separator = ',',
        decimal_number = '2',
        decimal_separator = '.',
        show_minus = BEFORE_VALUE,
        minus_sign = '-'
    } = config;

    // Replace group and decimal separators
    let formattedPrice = Math.abs(price)
        .toFixed(decimal_number)
        .replace('.', decimal_separator)
        .replace(/\B(?=(\d{3})+(?!\d))/g, group_separator);

    // Handle negative values
    if (price < 0) {
        formattedPrice = show_minus === BEFORE_VALUE
            ? minus_sign + formattedPrice
            : formattedPrice + minus_sign;
    }

    // Append/prepend the currency symbol based on show_symbol config
    switch (show_symbol) {
    case BEFORE:
        formattedPrice = `${symbol}${formattedPrice}`;
        break;
    case BEFORE_WITH_SPACE:
        formattedPrice = `${symbol} ${formattedPrice}`;
        break;
    case AFTER:
        formattedPrice = `${formattedPrice}${symbol}`;
        break;
    case AFTER_WITH_SPACE:
        formattedPrice = `${formattedPrice} ${symbol}`;
        break;
    default:
        break;
    }

    return formattedPrice;
};

/** @namespace Bodypwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const { currency_configurations } = getStore().getState().ConfigReducer;
    const config = JSON.parse(currency_configurations || '{}');
    // Use custom formatting if available in PRICE_CONFIGS
    if (config[currency]) {
        return applyCustomFormatting(price, currency, config[currency]);
    }

    // Fallback to default Intl formatting if no custom config
    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Bodypwa/Util/Price/roundPrice */
export const roundPrice = (price) => parseFloat(price).toFixed(2);

/** @namespace Bodypwa/Util/Price/getLowestPriceTiersPrice */
export const getLowestPriceTiersPrice = (price_tiers, currency) => {
    const lowestValue = price_tiers
        .reduce((acc, { final_price: { value } }) => (acc < value ? acc : value), price_tiers[0].final_price.value);

    return formatPrice(lowestValue, currency);
};
